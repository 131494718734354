import currency from 'currency.js';
import hooks from '../common/hooks';
import './PaymentOverview.scss';
import * as appConstants from '../constants/appConstants';

const getAccountName = (logoURL, accountName) => {
  let prefix;
  if (appConstants.display.defaultLogoImage === logoURL) {
    prefix = 'Pay ';
  } else {
    prefix = 'Online Payment to ';
  }

  return accountName && `${prefix} ${accountName}`;
};

function PaymentOverview({
  totalAmount,
  accountName,
  logoURL,
  isConvenienceFeeApplied,
  invoiceAmount,
  invoiceNumber,
  checkoutMessage,
  paymentMethod,
  cardConvenienceFee,
  echeckConvenienceFee
}) {
  const formatCurrency = hooks.useCurrencyFormat();

  return (
    <div className="payment-overview col-md-6 col-sm-12">
      <div className="container-fluid">
        <div className="logo">
          <img alt="" src={logoURL || appConstants.display.defaultLogoImage} />
        </div>
        <div className="firm">
          <span>{getAccountName(logoURL, accountName)}</span>
        </div>
        <div className="currency">
          <span>
            {totalAmount && totalAmount > 0
              ? formatCurrency(currency(totalAmount).value)
              : '$---.--'}
          </span>
        </div>
        {checkoutMessage && (
          <div className="checkout_message">
            <span>{checkoutMessage}</span>
          </div>
        )}
        {Number.isFinite(invoiceAmount) && (
          <div className="invoice-amount">
            <div>Invoice</div>
            <div>{formatCurrency(currency(invoiceAmount).value)}</div>
          </div>
        )}
        {invoiceNumber && <div className="invoice-number">Invoice #{invoiceNumber}</div>}
        {isConvenienceFeeApplied &&
          paymentMethod === appConstants.methodFee.card &&
          !Number.isNaN(cardConvenienceFee) &&
          cardConvenienceFee !== 0 && (
            <div className="conditions">
              <span>Payor Surcharge applies</span>
            </div>
          )}
        {isConvenienceFeeApplied &&
          paymentMethod === appConstants.methodFee.us_bank_account &&
          !Number.isNaN(echeckConvenienceFee) &&
          echeckConvenienceFee !== 0 && (
            <div className="conditions">
              <span>Payor Surcharge applies</span>
            </div>
          )}
      </div>
    </div>
  );
}

export default PaymentOverview;
