import './PaymentForm.scss';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import currency from 'currency.js';
import hooks from '../common/hooks';
import * as appConstants from '../constants/appConstants';
import { getConvenienceFee } from '../services/app.service';
import CardIcon from '../images/card_icon.svg';
import BankIcon from '../images/bank_icon.svg';

function PaymentForm({
  clientName,
  setTotalAmount,
  email,
  serviceErrorText,
  submitPayment,
  payConfig,
  accounts,
  setRemoteId,
  remoteId,
  setPaymentMethod,
  paymentMethod
}) {
  const formatCurrency = hooks.useCurrencyFormat();
  const [payAmount, setPayAmount] = useState('');
  const [invNo, setInvNo] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted },
    reset,
    formState
  } = useForm();
  useEffect(() => {
    reset();
  }, []);
  const onSubmit = (data) => {
    submitPayment({
      paymentInvNo: data['pay-invoice'],
      paymentEmail: data['pay-email'],
      paymentNote: data['pay-notation'],
      remoteId,
      paymentClientName: data['pay-client-name'],
      paymentMethod
    });
  };
  const getFormControlErrorForId = (id) => {
    return `form-control ${isSubmitted && (errors?.[id] ? 'is-invalid' : 'is-valid')} `;
  };

  const getRoundedAmountString = (amount) => {
    if (amount !== '' && amount !== undefined && !Number.isNaN(amount)) {
      const val = parseFloat(amount).toFixed(2);
      return val;
    }
    return '';
  };

  const sanitizeNumber = (val) => {
    // eslint-disable-next-line eqeqeq
    if (val !== undefined && Number(val) == val && !Number.isNaN(val)) {
      return parseFloat(val);
    }
    return '';
  };

  const sanitizeInt = (val) => {
    // eslint-disable-next-line eqeqeq
    if (val !== undefined && Number(val) == val && !Number.isNaN(val)) {
      return parseInt(val, 10);
    }
    return '';
  };

  useEffect(() => {
    let timer;
    if (!serviceErrorText)
      timer = setTimeout(() => {
        setValue('pay-email', email);
      }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, [email, setValue, serviceErrorText]);

  useEffect(() => {
    setTimeout(() => {
      const currentErrors = { ...formState.errors };
      if (!currentErrors?.['pay-amount']) {
        let totalAmount = 0;
        let localConvenienceFee = payConfig.allowConvenienceFee
          ? getConvenienceFee(payAmount, payConfig.cardConvenienceFee)
          : 0;
        totalAmount = currency(+payAmount).add(localConvenienceFee || 0).value;
        if (paymentMethod !== appConstants.methodFee.card) {
          localConvenienceFee = payConfig.allowConvenienceFee
            ? getConvenienceFee(payAmount, payConfig.echeckConvenienceFee)
            : 0;
          totalAmount = currency(+payAmount).add(localConvenienceFee || 0).value;
        }
        setTotalAmount(totalAmount);
      } else {
        setTotalAmount();
      }
    }, 0);
  }, [payAmount, formState]);

  const numberInputOnWheelPreventChange = (e) => {
    const payment = payAmount;
    const invoice = invNo;
    // Prevent the input value change
    e.target.blur();
    // Prevent the page/container scrolling
    e.stopPropagation();
    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
      setPayAmount(payment);
      setInvNo(invoice);
    }, 0);
  };

  const handlePaymentMethod = (strPaymentMethod) => {
    setPaymentMethod(strPaymentMethod);
  };

  return (
    <div className="payment-form col-md-6 col-sm-12">
      <div className="container-fluid form-section">
        <div className="buttonAnddividerContainer">
          <div className="divider">
            <hr />
            <p className="divider-text text-secondary">How much do you want to pay today?</p>
          </div>
        </div>
        <div className="xs-12">
          <p
            className="divider-text text-danger text-uppercase text-center"
            id="errorMessage"
            name="errorMessage">
            {serviceErrorText}
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} noValidate className="needs-validation">
          {accounts.length !== 0 && (
            <div className="form-group">
              <label htmlFor="pay-type">Payment Type:</label>
              <select
                value={remoteId}
                onChange={(e) => {
                  setRemoteId(e.target.value);
                }}>
                <option value="">Select Payment Type</option>
                {accounts.map((account) => {
                  return (
                    <option key={account.remoteId} value={account.remoteId}>
                      {account.label}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <div className="form-group">
            <button
              type="button"
              onClick={() => {
                handlePaymentMethod(appConstants.methodFee.card);
              }}
              className={
                paymentMethod === appConstants.methodFee.card
                  ? 'buttonIcon activeMethod'
                  : 'buttonIcon inActiveNoborder'
              }>
              <img src={CardIcon} alt="Card" width={18} height={18} />
              <span className="PaddingIcon">Card</span>
              <input
                id="pay-method"
                className={getFormControlErrorForId('pay-client-name')}
                type="hidden"
                maxLength={100}
                value={paymentMethod}
              />
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button
              type="button"
              onClick={() => {
                handlePaymentMethod(appConstants.methodFee.us_bank_account);
              }}
              className={
                paymentMethod === appConstants.methodFee.us_bank_account
                  ? 'buttonIcon RightBox activeMethod'
                  : 'buttonIcon RightBox inActiveNoborder'
              }>
              <img src={BankIcon} alt="US Bank Account" width={18} height={18} />
              <span className="PaddingIcon">US bank account</span>
            </button>
          </div>
          <div className="form-group">
            <label htmlFor="pay-amount">Payment Amount:</label>
            <input
              id="pay-amount"
              className={getFormControlErrorForId('pay-amount')}
              value={payAmount}
              onPaste={(e) => {
                if (
                  appConstants.form.disallowedNumberFieldCharRegex.test(
                    e.clipboardData.getData('text')
                  ) ||
                  Number(e.clipboardData.getData('text')) < 0
                ) {
                  e.preventDefault();
                }
              }}
              onWheel={numberInputOnWheelPreventChange}
              onKeyDown={(e) => {
                if (
                  e.key === 'ArrowDown' ||
                  e.key === 'ArrowUp' ||
                  appConstants.form.disallowedNumberFieldCharRegex.test(e.key)
                ) {
                  e.preventDefault();
                }
              }}
              type="number"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('pay-amount', {
                disabled: (accounts.length && !remoteId) || !!serviceErrorText,
                required: 'Pay Amount is required',
                min: {
                  value:
                    payConfig.minAmount >= appConstants.payMinAmount
                      ? payConfig.minAmount
                      : appConstants.payMinAmount,
                  message:
                    payConfig.minAmount >= appConstants.payMinAmount
                      ? `Pay amount cannot be less than ${formatCurrency(payConfig.minAmount)}`
                      : `Pay amount cannot be less than ${formatCurrency(
                          appConstants.payMinAmount
                        )}`
                },
                max: {
                  value: payConfig.maxAmount,
                  message: `Pay amount cannot be more than ${formatCurrency(payConfig.maxAmount)}`
                },
                onChange: (e) => {
                  const val = e.target.value;
                  let chiselled = val;
                  if (!appConstants.form.allowOnlyTwoDigitDecimals.test(val)) {
                    chiselled =
                      val.indexOf('.') >= 0
                        ? val.substr(0, val.indexOf('.')) + val.substr(val.indexOf('.'), 3)
                        : val;
                    e.target.value = chiselled;
                  }
                  const sanitizedVal = sanitizeNumber(chiselled);
                  if (Number.isFinite(sanitizedVal)) {
                    if (sanitizedVal <= appConstants.form.maxAllowedPayValueinUI) {
                      setPayAmount(sanitizedVal);
                    }
                  } else {
                    setPayAmount('');
                  }
                },
                onBlur: () => {
                  const val = getRoundedAmountString(sanitizeNumber(payAmount));
                  setPayAmount(val);
                }
              })}
              placeholder=""
              // onFocus={() => {
              //   setPayAmount(getRoundedAmountString(sanitizeNumber(payAmount)));
              // }}
            />
            <span className="invalid-feedback">
              <ErrorMessage errors={errors} name="pay-amount" />
            </span>
          </div>

          <div className="form-group">
            <label htmlFor="pay-invoice">Invoice #:</label>
            <input
              id="pay-invoice"
              className={getFormControlErrorForId('pay-invoice')}
              value={invNo}
              type="number"
              onPaste={(e) => {
                if (
                  appConstants.form.disallowedNumberFieldCharRegex.test(
                    e.clipboardData.getData('text')
                  ) ||
                  Number(e.clipboardData.getData('text')) < 0
                ) {
                  e.preventDefault();
                }
              }}
              onWheel={numberInputOnWheelPreventChange}
              onKeyDown={(e) => {
                if (
                  e.key === 'ArrowDown' ||
                  e.key === 'ArrowUp' ||
                  appConstants.form.disallowedNumberFieldCharRegex.test(e.key)
                ) {
                  e.preventDefault();
                }
              }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('pay-invoice', {
                disabled: (accounts.length && !remoteId) || !!serviceErrorText,
                required: 'Invoice number is required',
                min: {
                  value: 0,
                  message: `Invoice number is invalid`
                },
                onChange: (e) => {
                  const sanitizedVal = sanitizeInt(e.target.value);
                  if (Number.isFinite(sanitizedVal)) {
                    if (sanitizedVal <= appConstants.form.maxAllowedInvNoinUI) {
                      setInvNo(sanitizedVal);
                    }
                  } else {
                    setInvNo('');
                  }
                },
                onBlur: () => {
                  const val = sanitizeInt(invNo);
                  if (!Number.isNaN(val)) {
                    setInvNo(val);
                  }
                }
              })}
            />
            <span className="invalid-feedback">
              <ErrorMessage errors={errors} name="pay-invoice" />
            </span>
          </div>

          <div className="form-group">
            <label htmlFor="pay-client-name">Client #:</label>
            <input
              id="pay-client-name"
              className={getFormControlErrorForId('pay-client-name')}
              type="text"
              maxLength={100}
              value={clientName}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('pay-client-name', {
                disabled:
                  (accounts.length && !remoteId) || !!serviceErrorText || Boolean(clientName),
                required: 'Client is required'
              })}
            />
            <span className="invalid-feedback">
              <ErrorMessage errors={errors} name="pay-client-name" />
            </span>
          </div>

          <div className="form-group">
            <label htmlFor="pay-email">Receipt Email:</label>
            <input
              id="pay-email"
              className={getFormControlErrorForId('pay-email')}
              type="email"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('pay-email', {
                disabled: (accounts.length && !remoteId) || !!serviceErrorText,
                required: 'Receipt Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: 'Valid Receipt Email is required'
                }
              })}
            />
            <span className="invalid-feedback">
              <ErrorMessage errors={errors} name="pay-email" />
            </span>
          </div>

          <div className="form-group">
            <label htmlFor="pay-notation">Notation:</label>
            <textarea
              id="pay-notation"
              className={getFormControlErrorForId('pay-notation')}
              maxLength={500}
              type="textarea"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('pay-notation', {
                disabled: (accounts.length && !remoteId) || !!serviceErrorText,
                required: 'Notation is required'
                // minLength: {
                //   value: 10,
                //   message: "Notation should have at least 10 characters",
                // },
              })}
            />
            <span className="invalid-feedback">
              <ErrorMessage errors={errors} name="pay-notation" />
            </span>
          </div>

          <div className="mt-4">
            <span className="font-weight-bold text-sm description">
              {`By clicking Next you acknowledge to pay ${
                (!errors?.['pay-amount'] && formatCurrency(payAmount)) || '$---.--'
              } towards the
              invoice`}
              {payConfig.allowConvenienceFee &&
                paymentMethod === appConstants.methodFee.card &&
                !Number.isNaN(payConfig.cardConvenienceFee) &&
                payConfig.cardConvenienceFee !== 0 &&
                ` and the ${
                  (!errors?.['pay-amount'] &&
                    formatCurrency(getConvenienceFee(payAmount, payConfig.cardConvenienceFee))) ||
                  '---.--'
                } payor surcharge`}
              {payConfig.allowConvenienceFee &&
                paymentMethod === appConstants.methodFee.us_bank_account &&
                !Number.isNaN(payConfig.echeckConvenienceFee) &&
                payConfig.echeckConvenienceFee !== 0 &&
                ` and the ${
                  (!errors?.['pay-amount'] &&
                    formatCurrency(getConvenienceFee(payAmount, payConfig.echeckConvenienceFee))) ||
                  '---.--'
                } payor surcharge`}
              .
            </span>

            <button
              type="submit"
              className="btn btn-primary mb-2 mt-2 w-100 NextButton"
              disabled={(accounts.length && !remoteId) || !!serviceErrorText}>
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PaymentForm;
